import { Action, Dispatch } from "redux";
import "whatwg-fetch";
import * as http from "../../util/http";

export const throwControllerError = async (
    dispatch: Dispatch<Action>,
): Promise<unknown> => {
    const path = `api/debug/unhandled-complex`;
    const requestDescription = http.newRequestDescription();
    try {
        const request = await http.get(requestDescription, path);
        const data = request.data;
        return data;
    } catch (error) {
        return http.errorHandler(error, requestDescription, dispatch);
    }
};
