import { PrimaryButton } from "components/ui/primary-button";
import { useGraphqlDebugQuery } from "generated/graphql";
import * as React from "react";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { throwControllerError } from "store/actions/debug";
import { Content, SectionHeader, Stage } from "../layout";

export const Debug: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const [badQueryEnabled, setBadQueryEnabled] = React.useState(false);
    const [controllerErrorResult, setControllerErrorResult] = React.useState<
        unknown | null
    >(null);
    const getControllerError = useCallback(async () => {
        const result = await throwControllerError(dispatch);
        setControllerErrorResult(result);
    }, [dispatch, setControllerErrorResult]);
    const badQuery = useGraphqlDebugQuery(
        {
            expectedResult: "error",
        },
        { enabled: badQueryEnabled },
    );
    const [renderSomethingSilly, setRenderSomethingSilly] = React.useState(false);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const foo: any = {
        bar: { baz: 1 },
    };
    return (
        <Stage>
            <SectionHeader title="Debug" />
            <Content>
                <div className="space-y-16">
                    <div className="space-y-1">
                        <h2 className="text-lg font-semibold">Client-side error</h2>
                        <PrimaryButton
                            onClick={() => {
                                throw new Error("Client-side error");
                            }}>
                            Throw error
                        </PrimaryButton>
                    </div>
                    <div className="space-y-1">
                        <h2 className="text-lg font-semibold">
                            Render something very silly client-side
                        </h2>
                        <PrimaryButton onClick={() => setRenderSomethingSilly(true)}>
                            Throw error
                        </PrimaryButton>
                        {renderSomethingSilly ? (
                            foo.bar.baz.blah()
                        ) : (
                            <p>Rendering normal things</p>
                        )}
                    </div>
                    <div className="space-y-1">
                        <h2 className="text-lg font-semibold">
                            Server-side graphql error
                        </h2>
                        <PrimaryButton
                            onClick={() => {
                                setBadQueryEnabled(true);
                            }}>
                            Throw error
                        </PrimaryButton>
                        <pre>
                            {JSON.stringify(
                                {
                                    data: badQuery.data,
                                    loading: badQuery.isLoading,
                                    error: badQuery.error,
                                },
                                null,
                                2,
                            )}
                        </pre>
                    </div>
                    <div className="space-y-1">
                        <h2 className="text-lg font-semibold">
                            Server-side controller error
                        </h2>
                        <PrimaryButton onClick={getControllerError}>
                            Throw error
                        </PrimaryButton>
                        <pre>{JSON.stringify(controllerErrorResult, null, 2)}</pre>
                    </div>
                </div>
            </Content>
        </Stage>
    );
};
